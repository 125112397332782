var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "gov-width-container",
    [
      _vm.loading
        ? _c("ck-loader")
        : [
            _c("vue-headful", {
              attrs: {
                title:
                  _vm.appName +
                  " - Add Service Location for: " +
                  _vm.service.name
              }
            }),
            _c(
              "gov-back-link",
              {
                attrs: {
                  to: {
                    name: "services-show-locations",
                    params: { service: _vm.service.id }
                  }
                }
              },
              [_vm._v("Back to service")]
            ),
            _c(
              "gov-main-wrapper",
              [
                _c(
                  "gov-grid-row",
                  [
                    _c(
                      "gov-grid-column",
                      { attrs: { width: "one-half" } },
                      [
                        _c("gov-heading", { attrs: { size: "xl" } }, [
                          _vm._v("Service locations")
                        ]),
                        _c("gov-heading", { attrs: { size: "m" } }, [
                          _vm._v("Add service location")
                        ]),
                        _c("service-location-form", {
                          attrs: {
                            errors: _vm.form.$errors,
                            "location-errors": _vm.locationForm.$errors,
                            location_type: _vm.location_type,
                            location_id: _vm.form.location_id,
                            name: _vm.form.name,
                            address_line_1: _vm.locationForm.address_line_1,
                            address_line_2: _vm.locationForm.address_line_2,
                            address_line_3: _vm.locationForm.address_line_3,
                            city: _vm.locationForm.city,
                            county: _vm.locationForm.county,
                            postcode: _vm.locationForm.postcode,
                            country: _vm.locationForm.country,
                            has_induction_loop:
                              _vm.locationForm.has_induction_loop,
                            has_wheelchair_access:
                              _vm.locationForm.has_wheelchair_access,
                            has_accessible_toilet:
                              _vm.locationForm.has_accessible_toilet,
                            regular_opening_hours:
                              _vm.form.regular_opening_hours,
                            holiday_opening_hours:
                              _vm.form.holiday_opening_hours
                          },
                          on: {
                            "update:location_type": function($event) {
                              _vm.location_type = $event
                            },
                            "update:location_id": function($event) {
                              return _vm.$set(_vm.form, "location_id", $event)
                            },
                            "update:name": function($event) {
                              return _vm.$set(_vm.form, "name", $event)
                            },
                            "update:address_line_1": function($event) {
                              return _vm.$set(
                                _vm.locationForm,
                                "address_line_1",
                                $event
                              )
                            },
                            "update:address_line_2": function($event) {
                              return _vm.$set(
                                _vm.locationForm,
                                "address_line_2",
                                $event
                              )
                            },
                            "update:address_line_3": function($event) {
                              return _vm.$set(
                                _vm.locationForm,
                                "address_line_3",
                                $event
                              )
                            },
                            "update:city": function($event) {
                              return _vm.$set(_vm.locationForm, "city", $event)
                            },
                            "update:county": function($event) {
                              return _vm.$set(
                                _vm.locationForm,
                                "county",
                                $event
                              )
                            },
                            "update:postcode": function($event) {
                              return _vm.$set(
                                _vm.locationForm,
                                "postcode",
                                $event
                              )
                            },
                            "update:country": function($event) {
                              return _vm.$set(
                                _vm.locationForm,
                                "country",
                                $event
                              )
                            },
                            "update:has_induction_loop": function($event) {
                              return _vm.$set(
                                _vm.locationForm,
                                "has_induction_loop",
                                $event
                              )
                            },
                            "update:has_wheelchair_access": function($event) {
                              return _vm.$set(
                                _vm.locationForm,
                                "has_wheelchair_access",
                                $event
                              )
                            },
                            "update:has_accessible_toilet": function($event) {
                              return _vm.$set(
                                _vm.locationForm,
                                "has_accessible_toilet",
                                $event
                              )
                            },
                            "update:regular_opening_hours": function($event) {
                              return _vm.$set(
                                _vm.form,
                                "regular_opening_hours",
                                $event
                              )
                            },
                            "update:holiday_opening_hours": function($event) {
                              return _vm.$set(
                                _vm.form,
                                "holiday_opening_hours",
                                $event
                              )
                            },
                            "update:image_file_id": function($event) {
                              _vm.form.image_file_id = $event
                            },
                            clear: function($event) {
                              return _vm.form.$errors.clear($event)
                            },
                            "clear-location": function($event) {
                              return _vm.locationForm.$errors.clear($event)
                            },
                            "alt-text-changed": function($event) {
                              _vm.altTextChanged = true
                            },
                            "image-changed": function($event) {
                              _vm.imageChanged = true
                            }
                          }
                        }),
                        _c("gov-section-break", { attrs: { size: "l" } }),
                        _vm.submitting
                          ? _c(
                              "gov-button",
                              { attrs: { disabled: "", type: "submit" } },
                              [_vm._v("Creating...")]
                            )
                          : _c(
                              "gov-button",
                              {
                                attrs: { type: "submit" },
                                on: { click: _vm.onSubmit }
                              },
                              [_vm._v("Create")]
                            ),
                        _vm.form.$errors.any() || _vm.locationForm.$errors.any()
                          ? _c("ck-submit-error")
                          : _vm._e()
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            )
          ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }